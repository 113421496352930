.App {
  text-align: center;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* web */
@media only screen and (min-width: 769px) {
  div#header {
    height: 197px;
    background-image: url("/public/img/bg-header.png");
    background-repeat: repeat-x;

    width: 100%;
    margin-top: 0px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  div#header .header-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 900px;
    padding-top: 20px;
  }

  div#header .header-logo {
    margin-left: 10px;
    padding-top: 2px;
  }

  div#header .header-meio {
    width: 100%;
    max-width: 560px;
    margin-top: 10px;
  }

  div#header .header-meio h1 {
    font-size: 48px;
    font-family: "Open Sans", sans-serif;
  }

  div#header .header-meio h2 {
    font-size: 18px;
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
    margin-top: -80px;
    margin-left: 105px;
  }

  div#header .header-right {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
    height: 30px;
    margin-right: 20px;
    margin-top: 10px;
  }

  div#header .header-right div {
    width: 150px;
    display: flex;
    margin-bottom: 20px;
    gap: 10px;
    align-items: center;
  }

  div#header .header-right div img {
    width: 26px;
    height: 26px;
  }

  div#header .header-right div a {
    color: black;
    font-family: "Open Sans", sans-serif;
    text-decoration: none;
  }

  div#produtos {
    width: 900px;
    max-width: 900px;
    margin: auto;
    margin: 20px;
    flex-wrap: wrap;
  }

  div#produtos .produtos-container {
    width: 100%;
    gap: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  div#produtos .produtos-container .card {
    width: 200px;
    margin-bottom: 20px;
    vertical-align: top;
    height: 240px;
  }

  div#produtos .produtos-container .img-container {
    border: 1px solid green;
    height: 150px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.card img {
    height: 120px !important;
  }

  div#produtos .produtos-container .card-container h2 {
    font-size: 14px;
    font-weight: 600;
    margin: 0px;
    padding: 0px;
  }

  div#produtos .produtos-container .card-container h3 {
    font-size: 14px;
    font-weight: 600;
    margin: 0px;
    padding: 0px;
  }

  div#produtos .produtos-container .card-container h4 {
    font-size: 13px;
    font-weight: normal;
    margin: 0px;
    padding: 0px;
  }

  div#body {
    margin: auto;
  }

  div#body .texto-abertura {
    text-align: justify;
    width: 500px;
    margin: 0px auto 30px auto;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
  }

  div#filter {
    margin: 20px;
    padding: 10px;
    height: 70px;
    border: 1px solid gray;
    border-radius: 5px;
    align-items: center;
    width: 100%;
  }

  div#filter .title {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    text-align: left;
    margin: 0;
    padding: 0;
    padding-bottom: 10px;
    width: 100%;
    font-weight: bold;
  }

  div#filter .filter-container {
    width: 100%;
    display: flex;
    gap: 20px;
  }

  div.app-container {
    width: 900px;
    max-width: 900px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin: auto;
  }

  div#rodape {
    width: 100%;
  }

  div.selo {
    margin: 30px 20px 20px 20px;
  }

  div.colabore {
    margin: 30px 20px 20px 20px;
  }

  div.colabore a {
    padding: 10px;
    height: 30px;
    background-color: #00ab4e;
    border-radius: 5px;
    border: 1px solid black;
    text-decoration: none;
    color: white;
  }

  div.colabore a:hover {
    border: 1px solid #00ab4e;
    text-decoration: none;
    color: white;
    box-shadow: black;
  }

  div#rodape {
    display: flex;
    width: 100%;
    border-top: 1px solid #eec900;
  }

  div#rodape .rodape-container {
    height: 50px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
  }
}

/* mobile */
@media only screen and (max-width: 768px) {
  div#header {
    height: 125px;
    background-image: url("/public/img/bg-header-mob.png");
    background-repeat: repeat-x;

    width: 100%;
    margin-bottom: 190px;
  }

  div#body .texto-abertura {
    border-top: 1px solid #eec900;
    width: 90%;
    margin: 40px 20px 20px 20px;
    padding-top: 10px;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
  }

  div.youtube {
    margin-bottom: 25px;
  }

  div.youtube iframe {
    width: 90%;
  }

  div#header .header-container {
    width: 100%;
    max-width: 768px;
  }

  div#header .header-logo {
    padding-top: 20px;
  }

  div#header .header-logo img {
    width: 100px;
  }

  div#header .header-meio {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  div#header .header-meio img {
    width: 250px;
  }

  div#header .header-meio h2 {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
    margin-top: 0px;
  }

  div#header .header-right {
    display: flex;
    align-items: center;
    gap: 5px;
    height: 30px;
    width: 80%;
    justify-content: space-between;
    margin: auto;
  }

  div#header .header-right div {
    width: 150px;
    display: flex;
    margin: 0px auto 20px auto;
    gap: 10px;
    align-items: center;
  }

  div#header .header-right div img {
    width: 26px;
    height: 26px;
  }

  div#header .header-right div a {
    color: black;
    font-family: "Open Sans", sans-serif;
    text-decoration: none;
  }

  div.selo {
    margin: -10px 20px 30px 20px;
  }

  div.selo img {
    width: 350px;
  }

  div.colabore a {
    font-size: 14px;
    padding: 10px;
    height: 40px;
    background-color: #00ab4e;
    border-radius: 5px;
    border: 1px solid black;
    text-decoration: none;
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  div#rodape {
    display: flex;
    width: 95%;
    border-top: 1px solid #eec900;
    margin: auto;
  }

  div#rodape .rodape-container {
    height: 50px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    width: 280px;
  }

  div#filter {
    margin: 30px auto 10px auto;
    padding: 20px;
    height: 120px;
    border: 1px solid gray;
    border-radius: 5px;
    display: flex;
    width: 80%;
    max-width: 768px;
    flex-wrap: wrap;
  }

  div#filter .title {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    text-align: left;
    margin: 0;
    padding: 0;
    padding-bottom: 10px;
    width: 100%;
    font-weight: bold;
  }

  div#filter .filter-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  div#produtos {
    width: 90%;
    margin: auto;
    margin: 20px;
    flex-wrap: wrap;
  }

  div#produtos .produtos-container {
    width: 100%;
    gap: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  div#produtos .produtos-container .card {
    width: 200px;
    margin-bottom: 20px;
    vertical-align: top;
    height: 240px;
  }

  div#produtos .produtos-container .img-container {
    border: 1px solid green;
    height: 150px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.card img {
    height: 120px !important;
  }

  div#produtos .produtos-container .card-container h2 {
    font-size: 14px;
    font-weight: 600;
    margin: 0px;
    padding: 0px;
  }

  div#produtos .produtos-container .card-container h3 {
    font-size: 14px;
    font-weight: 600;
    margin: 0px;
    padding: 0px;
  }

  div#produtos .produtos-container .card-container h4 {
    font-size: 13px;
    font-weight: normal;
    margin: 0px;
    padding: 0px;
  }
}

